import { SearchCriteria } from '@esp/common/types';

export class CompaniesLinksSearchCriteria extends SearchCriteria {
  pageSize = 50;
  pageNumber = 1;

  constructor(options?: Partial<CompaniesLinksSearchCriteria>) {
    super(options);

    Object.assign(this, options);
  }
}
