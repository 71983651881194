import type {
  CompaniesLinksSearchCriteria,
  CreateLinkPayload,
  PatchLinkPayload,
  RemoveLinkPayload,
} from '@esp/companies/types-data-access';
import type { Contact } from '@esp/parties/types';

const ACTION_SCOPE = '[CompaniesLinks]';

export namespace CompaniesLinksActions {
  export class SearchContactsLinks {
    static type = `${ACTION_SCOPE} SearchContactsLinks`;

    constructor(
      public id: number,
      public criteria: Partial<CompaniesLinksSearchCriteria>
    ) {}
  }

  export class PrepareCriteria {
    static readonly type = `${ACTION_SCOPE} PrepareCriteria`;

    constructor(public criteria: CompaniesLinksSearchCriteria) {}
  }

  export class SearchWithExistingCriteria {
    static readonly type = `${ACTION_SCOPE} SearchWithExistingCriteria`;
  }

  export class CreateLink {
    static type = `${ACTION_SCOPE} CreateLink`;

    constructor(public payload: CreateLinkPayload) {}
  }

  export class CreateContact {
    static readonly type = `${ACTION_SCOPE} CreateContact`;

    constructor(public contact: Contact) {}
  }

  export class PatchLink {
    static type = `${ACTION_SCOPE} PatchLink`;

    constructor(public payload: PatchLinkPayload) {}
  }

  export class RemoveLink {
    static type = `${ACTION_SCOPE} RemoveLink`;

    constructor(public payload: RemoveLinkPayload) {}
  }
}
